<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title">我的账户</div>
      <pcBalanceHeader pageKey="rechargeRecord" />
      <div class="table-item">
        <el-table :data="recordList" stripe style="width: 100%" height="550">
          <el-table-column prop="Status" label="订单状态" align="center">
            <template #default="scope">
              {{ scope.row.Status == 3 ? '待结算' : scope.row.Status == 2 ? '待支付' : '已发放' }}
            </template>
          </el-table-column>
          <el-table-column prop="OrderNo" label="订单号" align="center"></el-table-column>
          <el-table-column prop="PayType" label="支付方式" align="center">
            <template #default="scope">
              {{ scope.row.PayType == 3 ? '微信支付' : scope.row.PayType == 2 ? 'QQ钱包支付' : '支付宝支付' }}
            </template>
          </el-table-column>
          <el-table-column prop="Money" label="充值金额" align="center">
            <template #default="scope">
              +{{ parseFloat(scope.row.Money).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="下单时间" align="center">
            <template #default="scope">
              {{ $TOOL.dateFormat(scope.row.AddTime * 1000, 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total > PageCount" class="pagination-item">
          <el-pagination background layout="prev, pager, next" :page-size="PageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcBalanceHeader from '@/components/pcBalanceHeader'

export default {
  name: "RechargeRecord",
  components: {
    pcPublicHeader,
    pcBalanceHeader
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      total: 0,
      loading: false,
      finish: false,
      recordList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetIncomeLogList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCurrentPageChange(val) {
      this.page = val
      this.handleGetIncomeLogList()
    },
    async handleGetIncomeLogList() {
      const { PageCount, Page } = this
      const params = {
        PageCount,
        Page,
        Type: 1
      }
      var res = await this.$API.incomeLogList.post(params)
      this.recordList = [
        ...res.info
      ]
      this.total = res.count
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  }
};
</script>

<style lang="less" scoped>
.page-title {
  padding: 30px 0 22px;
  line-height: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d42;
}
.table-item {
  padding: 30px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
}
</style>
